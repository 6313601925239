<template>
  <div :class="`floating-shortcuts ${isHomepageOverlay ? 'not-visible' : ''} ${isViewportLarge ? 'isLarge' : ''} ${isZoomed ? 'floating-zoomed' : ''}`" :aria-hidden="isZoomed">
    <!-- menu shortcuts -->
    <div :class="`menu ${isVisible ? 'menu-active' : ''} ${!isMenuOpen ? 'open' : 'close'}`" ref="fixedMenu">
        <a v-for="ctaObj in sliceFloatingShortcuts" :key="ctaObj.id" class="link">
            <advanced-button-wrapper :cta-obj="ctaObj" color="primary" styleType="text" size="medium" :analyticsData="analyticsData(ctaObj)">
              <div class="link__row">
                <div class="link__row--left">
                  <i :class="`icon-${ctaObj.marketingToolIcon}`"></i>
                  <div class="menu-title">
                    {{title(ctaObj.marketingToolId)}}
                  </div>
                </div>
                <i :class="`icon-arrow-dx`"></i>
              </div>
            </advanced-button-wrapper>
        </a>
      <!-- open/close button -->
      <div :class="`action-menu-button ${isMenuOpen ? 'open' : 'close'}`" role="button" tabindex="0"
           @click="toggleMenu(isMenuOpen)" @keyup.enter="toggleMenu(isMenuOpen)"
           :aria-label="$msg('common.floating-shortcuts.close-button.text')">
        <span v-if="!isMenuOpen && this.$mq === 'small'" class="close-button-text">{{ closeButtonText }}</span>
        <i v-if="!isMenuOpen" :class="`icon-plus close-icon`"></i>
        <i v-if="isMenuOpen" :class="`icon-action-menu`"></i>
      </div>
    </div>
  </div>
</template>

<script>
  import AdvancedButtonWrapper from "../Advanced-Button-Wrapper/Advanced-Button-Wrapper";
  import IconText from "../IconText/IconText.vue";
  import PgButton from "@wl-components/Button/Button.vue";
  import PgIcon from "@wl-components/Icons/Icon.vue";

  export default {
    name: "FloatingShortcuts",
    data() {
      return {
        actionParam: null,
        floatingShortcutsMap: {},
        scrollBarWidth: 0,
        isVisible: false,
        lastScrollPosition: 0,
        isMenuOpen: true
      };
    },
    components: {
      PgIcon,
      PgButton,
      AdvancedButtonWrapper,
      IconText
    },
    props: {
      floatingShortcuts: {
        type: Array,
        default: () => []
      },
      actpageFullUrl: {
        type: String,
        required: true,
        default: ""
      },
      common: {
        type: Object,
        default: () => {
        }
      },
    },
    computed: {
      title() {
        const ctx = this;
        return marketingToolId => {
          return ctx.$msg(`common.floating-shortcuts.${marketingToolId}.text`)
        }
      },
      closeButtonText() {
        return this.$msg('common.floating-shortcuts.close-button.text')
      },
      menuInit() {
        //Close menu if is mobile view on first loading
        return this.isMenuOpen = this.$mq !== 'small';
      },
      sliceFloatingShortcuts () {
        return this.floatingShortcuts.length >= 5 ? this.floatingShortcuts.slice(0, 5) : this.floatingShortcuts;
      },
      isViewportLarge() {
        return window.innerWidth > 1920;
      },
      viewportMarginValue() {
        return (window.innerWidth - 1920) / 2;
      },
      isZoomed() {
        // PIAGGIO-9042: content visibility with zoom 400%
        // check if desktop screen zoom is active
        return window.screen.width > 1230 && window.visualViewport.width < 1010;
      },
      isHomepageOverlay() {
        return this.common?.isOverlayConfigured ? this.common.isOverlayConfigured : false;
      },
    },

    watch: {
      isActive(newValue) {
        if(this.isViewportLarge && newValue) {
          this.$refs.fixedMenu.style.right = this.viewportMarginValue - this.scrollbarWidth + 'px';
        } else {
          this.$refs.fixedMenu.style.right = 0 + 'px';
        }
      },
    },

    mounted() {
      document.addEventListener('scroll', this.handleScroll);
      this.toggleMenu(this.menuInit);
    },

    destroyed() {
      window.removeEventListener('scroll', this.handleScroll);
    },

    methods: {
      handleScroll() {
        const isMobile = this.$mq !== 'small';
        const scrollY = window.scrollY || window.pageYOffset;
        this.isVisible = !isMobile && scrollY >= 1240 || isMobile && scrollY >= 1090 || scrollY >= this.lastScrollPosition;
        //Close sticky menu after 2370 scrollY
        if(scrollY >=  2370 && !this.isMenuOpen && this.isVisible) {
          this.toggleMenu(this.isMenuOpen);
        }
        this.lastScrollPosition = scrollY;
      },

      toggleMenu(isOpen) {
        const menuButtons = this.$refs.fixedMenu.childNodes;
        menuButtons.forEach(button => {
          if (button === menuButtons[0]) {
            button.classList.toggle('flex-row-btn', !isOpen);
          }
          if (button !== menuButtons[0] && button !== menuButtons[menuButtons.length - 1]) {
            button.classList.toggle('collapse', !isOpen);
          }
        });
        this.isMenuOpen = !this.isMenuOpen;
      },

      analyticsData(el) {
        return {
          evCategory: 'menu-sticky',
          evAction: el.trackingLabel,
          evLabel: `${el.campaignId || 'No Campaign'} | ${this.actpageFullUrl}`
        }
      }
    }
  };

</script>