<template>
  <div class="navigation-tabs">
    <div>
      <pg-button
          class="navigation__menu-nested__back"
          :aria-expanded="false"
          event-name="expandNested"
          :event-params="{uuid: item.uuid}"
      >
        <i class="icon-arrow-sx" aria-hidden="true"></i>
        {{ item.label }}
      </pg-button>
      <div class="navigation__menu-nested__headers-container">
        <h2 class="navigation__menu-nested__title">{{ item.label }}</h2>
        <ul role="tablist" class="navigation__menu-nested__nav-tabs">
          <li v-for="(subitem, i) in item['menu-tabs-area']" :key="`tab-header-${item.uuid}-${i}`" role="presentation" class="nav-tabs__nav-item"
              :ref="`menuTabHeader-${item.uuid}`">
            <a role="tab" aria-selected="true"
               :aria-setsize="item['menu-tabs-area'].length"
               :aria-posinset="i+1"
               target="_self" href="#"
               class="nav-tabs__nav-link text--heading-s"
               :class="tabIndex === i ? 'is-active' : ''"
               :id="`menu-tab__header-${i}`"
               :aria-controls="`menu-tab__body-${item.uuid}-${i}`"
               :tabindex="tabIndex === i ? '0' : '-1'"
               @click="activateTab($event, i)"
               @keyup.right="changeTab('right', i)"
               @keyup.left="changeTab('left', i)">
              {{ subitem.label }}
            </a>
          </li>
        </ul>
      </div>

      <div class="tab-content">
        <div v-for="(subitem, i) in item['menu-tabs-area']" :key="`tab-body-${item.uuid}-${i}`" role="tabpanel" :aria-hidden="[tabIndex !== i ? 'true' : 'false']"
             class="tab-pane active"
             :class="tabIndex === i ? 'show' : 'hide'"
             :id="`menu-tab__body-${item.uuid}-${i}`"
             :aria-labelledby="`menu-tab__header-${item.uuid}-${i}`" style="">

          <navigation-product-list
              :key="subitem.uuid"
              :item="subitem"
              :highlightItems="subitem.highlightItems"
              :posterImage="subitem.posterImage"
              :class="listingClasses(subitem.nestedItems.length)"
              :productCardConf="productCardConf"
              :isNavTabsChild="true">
          </navigation-product-list>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NavigationProductList from "./NavigationProductList";
import PgButton              from "../Button/Button.vue";

export default {
  name: "navigation-tabs",
  components: {
    NavigationProductList,
    PgButton
  },
  props: {
    item: {
      type: Object,
      required: true,
      default: () => {
      }
    },
    productCardConf: {
      type: Object,
      default: () => {
      }
    },
  },
  data() {
    return {
      tabIndex: 0
    };
  },
  methods: {
    changeTab(e, i) {
      switch (e) {
        case 'right':
          if (i < this.item["menu-tabs-area"].length - 1) this.tabIndex = i + 1;
          break;
        case 'left':
          if (i > 0) this.tabIndex = i - 1;
          break;
      }
      this.$refs[`menuTabHeader-${this.item.uuid}`][this.tabIndex].firstElementChild.focus();
    },
    activateTab(e, i) {
      e.preventDefault();
      this.tabIndex = i;
    },
    listingClasses: function (length) {
      let listingType;
      if (length <= 6) {
        listingType = "listing--small-2-large-3";
      } else {
        listingType = "listing--small-2-large-4";
      }
      return ["listing", listingType];
    }
  }
};
</script>