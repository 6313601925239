<template>
  <div :class="classes">
    <ul>
      <li v-for="(color) in colorsToShow" :key="color.uuid">
        <pg-swatch :color="color" />
      </li>
      <li v-if="moreColors">
        <div class="swatches-color">
          <i class="icon-plus swatches-color__more">
            <msg :id="'common.swatches.more-colors.aria'" />
          </i>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import PgSwatch from "../Swatch/Swatch.vue";
export default {
  name: "PgSwatches",
  components: {
    PgSwatch
  },
  props: {
    colors: {
      type: Array
    }
  },
  computed: {
    classes() {
      return ["swatches"];
    },
    colorsToShow() {
      return this.colors.slice(0, 5);
    },
    moreColors() {
      return this.colors.length > 5;
    }
  },
  methods: {
    colorStyle(code) {
      return "color:" + code;
    }
  }
};
</script>

<style scoped>
#root > * {
  margin: 5rem;
}
</style>
