<script>
  export default {
    name: "DebugPanel",

    data: function () {
      return {
        open: false
      }
    },

    methods: {
      toggle: function () {
        this.open = !this.open;
      }
    },

    computed: {
      getLocalData: function () {
        return this.$parent.$data;
      },

      getModelData: function () {
        return this.$parent.model;
      },

      getParentName: function () {
        return this.$parent.$vnode && this.$parent.$vnode.tag;
      }
    }
  }
</script>

<template>
    <div class="debug-panel" v-if="$config.debugMode">
        <h3>Debug info for component {{getParentName}}</h3>
        <button @click="toggle()">Show / hide debug info</button>
        <div class="panel" :class="{open: open, close: !open}">
            <div>
                <strong>Local data:</strong>
                <pre>{{getLocalData}}</pre>
            </div>
            <div>
                <strong>Model data:</strong>
                <pre>{{getModelData}}</pre>
            </div>
        </div>
    </div>
</template>

<style scoped>

</style>