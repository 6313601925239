<template>
  <div class="accordion">
    <div :class="{'font-size-ms' : isThemeVespa}" v-for="group in column" :key="group.id">
      <h3 :class="isThemeVespa ? 'text--heading-ms' : 'text--heading-s'" class="show-for-large">{{group.title}}</h3>
      <pg-button
        class="text--heading-s button--clear hide-for-large"
        :aria-expanded="isActiveElement(group.id)"
        :eventName="eventName"
        :event-params="{id: group.id}"
        :aria-controls="group.id"
      >
        {{group.title}}
        <svg
          width="40"
          height="12"
          stroke="cornflowerblue"
          class="accordion__trigger-Icon"
        >
          <polyline points="12,2 20,10 28,2" stroke-width="3" fill="none" />
        </svg>
      </pg-button>

      <div class="accordion__content" :class="{'is-visible': isActiveElement(group.id)}">
        <ul :id="group.id">
          <li v-for="item in group.items" :key="item.uuid">
            <a
              :href="item.link | mgnlLink('url')"
              :target="item.onBlank ? '_blank' : '_self'"
              :linkNofollow="item.noFollow"
            >{{ item.label }}</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import PgButton from "../Button/Button.vue";

export default {
  name: "FooterColumn",
  components: {
    PgButton
  },
  data() {
    return {
      actives: []
    };
  },
  props: {
    column: {
      type: Array,
      default: () => []
    },
    columnId: {
      type: String
    }
  },
  computed: {
    eventName: function() {
      return "expandFooterGroup-" + this.columnId;
    },
    isActiveElement: function() {
      let ctx = this;
      return function(id) {
        return ctx.actives.includes(id);
      };
    },
    isThemeVespa() {
      // Check if the body contains the class "theme-vespa"
      return document.body.classList.contains('theme-vespa');
    }
  },
  created: function() {
    let ctx = this;
    this.$eventHub.on(ctx.eventName, function(eventParams) {
      let index = ctx.actives.indexOf(eventParams.id);
      if (index >= 0) {
        ctx.actives.splice(index, 1);
      } else {
        ctx.$set(ctx.actives, ctx.actives.length, eventParams.id);
      }
    });
  }
};
</script>
