const arrayMove = function (arr, fromIndex, toIndex) {
  const element = arr[fromIndex];
  let arrayCopy = [...arr];
  arrayCopy.splice(fromIndex, 1);
  arrayCopy.splice(toIndex, 0, element);
  return arrayCopy;
}

const arraySortBy = function (arr, property, order = 'desc') {
  const comparingSign1 = order === 'desc' ? -1 : 1;
  const comparingSign2 = order === 'desc' ? 1 : -1;
  let arrayCopy = [...arr];
  arrayCopy.sort((a, b) => (a[property] > b[property]) ? comparingSign1 : ((a[property] > b[property]) ? comparingSign2 : 0));
  return arrayCopy;
}


export {arrayMove, arraySortBy}