<template>
  <div>
    <!-- LOADER -->
    <loader v-if="loading"/>

    <div class="change-language-country change-language-country--list">
      <dl class="contry-list">
        <dt class="contry-list__continent-name">
          <h3>International</h3>
        </dt>
        <dd class="contry-list__country-name">
          <pg-button href="/en_EN/"
                     title="International"
                     :styleType="'text'"
          >
            <i v-if="showFlags" :class="flagIcon('EN')" aria-hidden="true">
              <!-- -->
            </i>
            International
          </pg-button>
        </dd>
      </dl>
      <dl class="contry-list" v-for="continent in countries.Continente" :key="continent.id">
        <dt class="contry-list__continent-name">
          <h3>{{continent.Name}}</h3>
        </dt>
        <dd class="contry-list__country-name" v-for="country in continent.Country" :key="country.id">
          <div v-if="country.CountrySite && country.CountrySite.length > 1">
            <i v-if="showFlags" :class="flagIcon(country.CountryCD)" aria-hidden="true">
              <!-- -->
            </i>
            {{country.Name}}
            <pg-button v-for="site in country.CountrySite"
                       class="contry-list__lang"
                       :href="site.URL"
                       :styleType="'text'"
                       :title="site.URLDescription"
                       :size="'small'"
                       :key="site.id">
              <!--{{site.URL.substring(site.URL.length, site.URL.length - 2)}}-->
              {{site.Language}}
            </pg-button>
          </div>
          <div v-else>
            <pg-button v-if="country.CountrySite && country.CountrySite[0].URL !== null"
                       :href="country.CountrySite[0].URL"
                       :title="country.Name"
                       :styleType="'text'"
            >
              <i v-if="showFlags" :class="flagIcon(country.CountryCD)" aria-hidden="true">
                <!-- -->
              </i>
              {{country.Name}}
            </pg-button>

            <pg-button v-else
                       v-b-modal="`modal-multi-${country.CountryCD}`"
                       :title="country.Name"
                       :size="'medium'">
              <i v-if="showFlags" :class="flagIcon(country.CountryCD)" aria-hidden="true">
                <!-- -->
              </i>
              {{country.Name}}
            </pg-button>

            <!-- Second modal (No website) -->
            <b-modal v-if="country.CountrySite && country.CountrySite[0].URL === null"
                     :id="modalCountryId(country.CountryCD)"
                     modal-class="modal--dialog"
                     :title="country.Name"
                     size="sm"
                     centered
                     hide-footer

            >
              <template v-slot:modal-header-close>
                <i class="close__icon icon-close" aria-hidden="true"></i>
                <span class="close__label">
                      <msg :id="'common.modal.close.text'"/>
                    </span>
              </template>
              <div class="text--body country-distributor">
                <div class="text--heading-xxs">
                  <msg :id="'common.official.distributor.text'"/>
                </div>
                <p>
                  <i class="icon-globe" aria-hidden="true"></i>
                  {{country.CountrySite[0].Name}} <br/>
                  {{country.CountrySite[0].Address}} <br/>
                  {{country.CountrySite[0].ZipCode}} {{country.CountrySite[0].City}} <br/>
                </p>
                <p v-if="country.CountrySite[0].Phone">
                  <a href="`tel:${country.CountrySite[0].Phone}`">
                    <i class="icon-contact-fill" aria-hidden="true"></i>
                    {{country.CountrySite[0].Phone}}
                  </a>
                </p>
                <p v-if="country.CountrySite[0].Email">
                  <a href="`mailto:${country.Email}`">
                    <i class="icon-email-fill" aria-hidden="true"></i>
                    {{country.CountrySite[0].Email}}
                  </a>
                </p>
                <p v-if="country.CountrySite[0].Fax">
                  <i class="icon-save-fill" aria-hidden="true"></i>
                  {{country.CountrySite[0].Fax}}
                </p>
              </div>
            </b-modal>
          </div>
        </dd>
      </dl>
    </div>
  </div>
</template>

<script>
  import PgButton from "../Button/Button.vue";
  import Loader from "../Loader/Loader.vue";

  export default {
    name: "Countries",
    data() {
      return {
        countries: [],
        loading: true
      }
    },
    components: {
      PgButton,
      Loader
    },
    props: {
      siteServiceParam: {
        type: String
      },
      showFlags: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      flagIcon: function () {
        const filters = this.$options.filters;
        return function (string) {
          if (!string) return false;
          return "flag-icon flag-icon-" + filters.subStr(string.toLowerCase());
        };
      },
    },
    methods: {
      getCountries: function () {
        let ctx = this;
        const siteServiceParam = this.siteServiceParam;
        let url = `https://stats.piaggio.com/getxmlsites/tree/?b=${siteServiceParam}&format=json`;

        ctx.$http
          .get(url)
          .then((response) => {
            ctx.loading = false;
            ctx.countries = response.data.CountrySites.Site;
          }, (error) => {
            ctx.loading = false;
          })
      },
      modalCountryId(id) {
        return 'modal-multi-' + id
      }
    },
    mounted() {
      //this.$eventHub.on("PgChangeLanguageCountry:changeCountry", (params) => {
      this.countries.length < 1 && this.getCountries();

      // });
    },
  }

</script>