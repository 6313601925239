<template>
  <div class="loader-wrap">
    <div class="loader">Loading...</div>
  </div>

</template>

<script>
  export default {
    name: "PgLoader"
  }
</script>