<template >
  <fragment v-if="bare">{{getMessage}}</fragment>
  <fragment v-else>
    <span :class="cssClass" v-if="parseHtml" v-html="getMessage"></span>
    <span :class="cssClass" v-else>{{getMessage}}</span>
  </fragment>
</template>

<script>
    import { Fragment } from 'vue-fragment'

    export default {
      // this vue component is registered globally in order to manage mgnl translations
      // to use it you only have to declare insider your template in this way: <msg id="piaggio.common.foo"/>
      name: "Msg",

      components: {
        Fragment
      },

      props: {
        "id": {
          type: String
        },
        "parseHtml": {
          type: Boolean,
          default: false
        },
        "cssClass": {
          type: String,
        },
        "bare": {
          type: Boolean,
          default: false
        },
        "params": {
          type: Array,
          default: () => []
        },
        "defaultMessage": {
          type: String,
          default: ''
        }
      },

      computed: {
        // retrieving messages dictionary from root element data, otherwise load mock from storybook mocks
        getMessage: function () {
          return this.$msg(this.id, this.params) || this.defaultMessage;
        }
      },
      mounted() {}
    }
</script>
