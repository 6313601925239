// filters collections

// mgnl media filter
//     "uuid" : mediaNode.getIdentifier(),
//     "url" : MediaEl.url(property),
//     "type" : PropertyUtil.getString(mediaNode, "type"),
//     "media_name" : PropertyUtil.getString(mediaNode, "media_name"),
//     "title" : PropertyUtil.getString(mediaNode, "title"),
//     "width"
//     "height"

const mgnlMedia = function (obj, attr, defaultValue = "") {
  const validAttrs = ["uuid", "url", "type", "media_name", "title", "width", "height"];

  if (!validAttrs.includes(attr) ) {
    console.warn(`Attribute ${attr} not recongnized by mgnlMedia filter`)
    
    
    return ""
  }

  if (!obj) return defaultValue

  return obj[attr]
}

const mgnlLink = function (obj, attr) {
  if (!obj) return ""
  return obj[attr]
}

const subStr = function (string, begin=0, end=2) {
  return (string || '').substring(begin, end);
}

const strippedContent = function(string) {
  if (string) {
    return string.replace(/<\/?[^>]+>/ig, " ");
  }
  return;
}

const formatPrice = function (value, priceFormattingConfig) {
  try {

    if (value === null || value === undefined) {
      return "";
    }

    if (typeof value !== "number") {
      value = parseFloat(value);
    }

    const decimalCount = 2
    const negativeSign = value < 0 ? "-" : "";
    const thousandsSeparator = priceFormattingConfig.priceThousandSep;
    const decimalSeparator = priceFormattingConfig.priceDecimalSep;
    const currency = priceFormattingConfig.priceCurrency;
    const startWithCurrency = priceFormattingConfig.priceCurrencyOnStart;
    const forceDecimal = priceFormattingConfig.forceDecimal || false;

    let i = parseInt(value = Math.abs(Number(value) || 0).toFixed(decimalCount)).toString();
    let j = (i.length > 3) ? i.length % 3 : 0;

    let integerPart = (j ? i.substr(0, j) + thousandsSeparator : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousandsSeparator);
    let decimalPart = (decimalCount ? Math.abs(value - i).toFixed(decimalCount).slice(2) : "");

    let price = `${startWithCurrency ? currency : ''} ${negativeSign}${integerPart}${(decimalPart > 0 || forceDecimal) ? decimalSeparator + decimalPart : ''} ${!startWithCurrency ? currency : ''}`;

    return price;
  } catch (e) {
    console.log(e)
  }
}

export { mgnlMedia, mgnlLink, subStr, strippedContent, formatPrice }
