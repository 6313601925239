<template>
  <div>
    <div v-for="(icon, index) in icons" class="legend" :key="index">
      <i :class="`icon-${icon}`"></i>
      <p>{{icon}}</p>
    </div>
  </div>
</template>

<script>
import iconsList from "./icons";

export default {
  name: "pg-icon",

  data: function() {
    return {
      icons: iconsList
    };
  }
};
</script>

<style scoped>
i[class^="icon-"]:before,
i[class*=" icon-"]:before {
  font-size: 26px;
  margin: 20px;
  display: inline-block;
}

.legend {
  margin: 20px;
  display: inline-block;
  text-align: center;
}

p {
  font-size: 14px;
}
</style>
