<template>
  <div :class="['navigation__menu-nested' , {'is-visible': isActiveElement}]"
       :id="'nav-submenu-'+ item.uuid">
    <component
        :is="secondLevelNavigationComponent" v-if="secondLevelNavigationComponent"
        :key="item.uuid"
        :item="itemToPass"
        :highlightItems="item.highlightItems"
        :posterImage="item.posterImage"
        :productCardConf="productCardConf"></component>
    <div v-else-if="isEditInterface && !item.nestedItems">
      <pg-button v-if="item.link" :href="item.link | mgnlLink('url')">{{ item.label }}</pg-button>
      <h2 v-else class="navigation__menu-nested__title">{{ item.label }}</h2>
    </div>
    <slot></slot>
  </div>
</template>

<script>
import PgButton from "../Button/Button.vue";
import NavigationTabs from "./NavigationTabs.vue";
import NavigationItemEditorial from "./NavigationItemEditorial.vue";
import NavigationProductList from "./NavigationProductList.vue";

export default {
  name: "MainNavigationItem",

  components: {
    PgButton,
    NavigationTabs,
    NavigationItemEditorial,
    NavigationProductList
  },

  props: {
    item: {
      type: Object
    },
    productCardConf: {
      type: Object,
      default: () => {
      }
    },
    index: {
      type: Number
    },
    isActiveElement: {
      type: Boolean,
      default: false
    },
    highlightItems: {
      type: Array,
      required: false,
      default: () => []
    },
    posterImage: {
      type: Object,
      required: false,
      default: () => ({})
    },
    isEditInterface: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    itemToPass() {
      return this.item['menu-tabs-area']?.length === 1 ? this.item['menu-tabs-area'][0] : this.item;
    },
    secondLevelNavigationComponent() {
      if (this.item['menu-tabs-area']?.length > 1 && !this.isEditInterface) return "NavigationTabs";
      else if ((this.item.nestedItems && this.item.productSelect) || this.item['menu-tabs-area']?.length === 1) return "NavigationProductList";
      else if (this.item.nestedItems) return "NavigationItemEditorial";
    }
  }
};
</script>
