<template>
  <div :class="[`navigation-v2__menu-nested v2`, { open: isActiveElement }]" :id="'nav-submenu-' + item.uuid"
       ref="NavMenuNested"
       :style="setHeight"
  >
    <!-- eslint-disable vue/require-component-is -->
    <component
        :is="secondLevelNavigationComponent"
        v-if="secondLevelNavigationComponent"
        :key="item.uuid"
        :item="item"
        :highlightItems="item.highlightItems"
        :posterImage="item.posterImage"
        :productCardConf="productCardConf"
    />
    <!-- eslint-enable vue/require-component-is -->
    <div v-else-if="isEditInterface && !item.nestedItems">
      <pg-button v-if="item.link" :href="item.link | mgnlLink('url')">{{ item.label }}</pg-button>
      <h2 v-else class="navigation__menu-nested__title">{{ item.label }}</h2>
    </div>
    <slot></slot>
    <!--div :class="['navigation-v2__content-nested-overlay', {'visible' : activeOverlay}]"></div-->
  </div>
</template>

<script>
import PgButton from "../Button/Button.vue";
import NavigationItemEditorialV2 from "./NavigationItemEditorialV2.vue";
import NavigationProductListV2 from "./NavigationProductListV2.vue";

export default {
  name: "MainNavigationItemV2",

  components: {
    PgButton,
    NavigationItemEditorialV2,
    NavigationProductListV2
  },

  data() {
    return {
      setHeight: '',
    }
  },

  props: {
    item: {
      type: Object,
      default: () => {}
    },
    productCardConf: {
      type: Object,
      default: () => {}
    },
    index: {
      type: Number,
      default: 0
    },
    isActiveElement: {
      type: Boolean,
      default: false
    },
    highlightItems: {
      type: Array,
      required: false,
      default: () => []
    },
    posterImage: {
      type: Object,
      required: false,
      default: () => ({})
    },
    isEditInterface: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    secondLevelNavigationComponent() {
      if ((this.item.nestedItems && this.item.productSelect) || this.item["menu-tabs-area"]?.length === 1) {
        return "NavigationProductListV2";
      } else if (this.item.nestedItems) {
        return "NavigationItemEditorialV2";
      } else {
        return false; // this return false is to work around the eslint-error
      }
    }
  },

  methods: {
    setModalHeight() {
      //in order to fix the animation, the height should have a value but the items number will change
      //in this case if the listing items are > 6 the height will be fit-content. (note that will break the animation)
      //TODO we need to calculate the height of listing__items in order to set a value for the height
      const minHeight = 292;
      if ((this.item.nestedItems && this.item.productSelect) || this.item["menu-tabs-area"]?.length === 1) {
        const multiplier = Math.ceil(this.item.nestedItems.length / 6);
        /* listingItemRef will be 0, the component is mounted when you click on the voice menù */
        //const listingItemRef = this.$refs.NavMenuNested.childNodes[0].childNodes[0].childNodes[1].childNodes[0].clientHeight;
        this.setHeight = multiplier > 1 ? `--multiply-height: fit-content` : `--multiply-height: ${minHeight}px`
      } else {
        this.setHeight = `--multiply-height: ${minHeight}px`
      }
    }
  },

  mounted() {
    window.screen.width > 1024 && this.setModalHeight();
    this.isActiveElement = false;
  }
};
</script>
