<template>
  <component :is="componentIs" :href="href" :class="classes" @click="sendAnalyticsData($event)">
    <slot name="select"></slot>
    <div>
      <i class="icon-brand" aria-hidden="true"></i>
      <div class="card-product__img">
        <pg-image :lazy-src="item.img" :productType="item.productType" :brand-code="common.brandCode" :category-code="item.mainCategoryCode"></pg-image>
        <i
          class="card-product__promo"
          v-if="(productCardConf[item.productType].showPromo && item.plannedPrice) || isToShowPromoLabel"
        >
          <msg id="common.card.promo.text" />
        </i>
      </div>
      <pg-swatches
        class="card-product__swatches"
        :colors="orderedColoredProducts"
        v-if="productCardConf[item.productType].showColors && item.colors && !isThemeVespa"
      ></pg-swatches>
      <div class="custom-swatches-colors-container">
        <div v-if="productCardConf[item.productType].showColors && item.colors && isThemeVespa" class="custom-swatches-color" v-for="(color, index) in orderedColoredProducts" :key="color.uuid">
          <div class="color-icon" :style="`background-color: ${color.code}; border: ${color.code !== ('#ffffff' || '#f9ffff') ? 'unset' : '1px solid #ccc'}`" />
        </div>
      </div>
      <p>
        <span class="card-product__name">{{getName}}</span>
        <span class="card-product__price" v-if="productCardConf[item.productType].showPrice">
          <span
            class="card-product__price__discounted"
            v-if="productCardConf[item.productType].showPromo && item.discountedPrice != null"
          >{{item.discountedPrice | formatPrice(priceFormatting)}}</span>
          <span class="card-product__price__list">
            {{item.price | formatPrice(priceFormatting)}}
            <i class="icon-divider" aria-hidden="true"></i>
          </span>
          <span
            class="card-product__price__planned"
            v-if="productCardConf[item.productType].showPlanned && item.plannedPrice"
          >
            <msg
              id="common.card-product.starting-from.text"
              :params="[{ key: 'price', value: $options.filters.formatPrice(item.plannedPrice, priceFormatting) }]"
              :bare="true"
            />
          </span>
        </span>
      </p>
    </div>
  </component>
</template>

<script>
import PgImage from "../Image/Image.vue";
import PgSwatches from "../Swatches/Swatches.vue";
import PgButton from "../Button/Button.vue";
import {mgnlComponentMixin} from "@wl-vue-mixins/mixins.js";
import {arrayMove, arraySortBy} from "@wl-vue-utils/common-utils";

export default {
  mixins: [mgnlComponentMixin], // !!! all the mgnl components must be configured with mgnlComponentMixin !!!
  name: "CardProduct",
  components: {
    PgImage,
    PgSwatches,
    PgButton
  },
  props: {
    item: {
      type: Object,
      default: () => {}
    },
    showPromoLabel: {
      type: Boolean,
      default: false
    },
    priceFormatting: {
      type: Object,
      default: () => {}
    },
    productCardConf: {
      type: Object,
      default: () => {
        return {
          vehicle: {
            showPrice: false,
            showPromo: false,
            showPlanned: false,
            showColors: false
          },
          family: {
            showPrice: false,
            showPromo: false,
            showPlanned: false,
            showColors: false
          }
        };
      }
    },
    value: {
      type: [String, Array],
      default: ""
    },
    identifier: {
      type: String,
      default: "uuid"
    },
    select: {
      type: String,
      default: ""
    },
    analyticsData: {
      type: Object,
      default: () => {
      }
    }
  },
  computed: {
    orderedColoredProducts() {
      // ordering colors by colorHex and putting default color as first
      const colors = (this.item.colors || []);
      let orderedByHex = arraySortBy(colors, "code", "desc");
      const currentPos = orderedByHex.findIndex((element) => element.defaultColor);
/*      console.log(colors);
      console.log(currentPos);*/

      if (currentPos > 0) {
        orderedByHex = arrayMove(orderedByHex, currentPos, 0);
      }

      return orderedByHex;
    },
    classes() {
      let selectClass = "";

      if (this.$slots.select !== undefined && this.value === this.item[this.identifier]) {
        selectClass = "is-selected";
      } else if (this.$slots.select !== undefined) {
        selectClass = "is-not-selected";
      }

      let cardClass = "";

      //console.log("productType", this.item.productType);

      switch (this.item.productType) {
        case "vehicle":
          cardClass = "card-product--vehicle";
          break;
        case "family":
          cardClass = "card-product--family";
          break;
        case "service":
          cardClass = "card-product--service";
          break;
        default:
          cardClass = "card-product--accessory";
          break;
      }

      return ["card-product", cardClass, selectClass];
    },
    componentIs() {
      let component = "a";

      //console.log(this.select);

      if (this.select === "direct") {
        component = "label";
      } else if (this.select === "confirm") {
        component = "button";
      } else if (this.select === "linksDisabled") {
        component = "div"
      }

      return component;
    },
    href() {
      let href = this.item.href;
      if (this.select === "direct" || this.select === "confirm" || this.select === "linksDisabled") {
        href = false;
      }
      return href;
    },
    isToShowPromoLabel() {
      return this.showPromoLabel && this.item.promo;
    },
    getName() {
      return this.item.extendedName ? this.item.extendedName : this.item.name;
    },
    isThemeVespa() {
      // Check if the body contains the class "theme-vespa"
      return document.body.classList.contains('theme-vespa');
    }
  },

  methods: {
    sendAnalyticsData(ev) {
      if (this.analyticsData) {
        if (window.google_tag_manager) ev.preventDefault();
        this.$analytics.pushEvent(this.analyticsData.evCategory, this.analyticsData.evAction, this.analyticsData.evLabel, this.href);
      }
    }
  }
};
</script>
