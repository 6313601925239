<template>
  <footer id="footer" :class="footerClass">
    <!-- PIAGGIO-8636: pre footer for community -->
    <template v-if="includeCommunityFooter">
      <section class="section section--bggray">
        <div class="section__container">
          <div class="grid-x align-center one_icon">
            <div class="editorial-icon">
              <h3>
                <pg-icon-text :iconName="communityFooterData.iconName" :title="communityFooterData.iconTitle" />
              </h3>
            </div>
          </div>
        </div>
      </section>
      <hr class="divider" />
      <section class="section section--bggray">
        <div class="section__container">
          <div class="grid-x align-center motoplex--custom">
            <editorial v-bind="communityFirstContact" />
            <editorial v-bind="communitySecondContact" />
          </div>
        </div>
      </section>
      <p style="padding-top: 2rem"></p>
    </template>
    <!-------->
    <h2 class="show-for-sr">
      <msg id="common.footer.title.aria" />
    </h2>
    <pg-image :lazy-src="logo | mgnlMedia('url')" :alt="brandName" class="footer__logo" v-if="Object.keys(logo || {}).length !== 0"></pg-image>
    <div class="footer__main" v-if="items.length > 0 || isEditInterface">
      <template v-if="!isEditInterface">
        <div class="footer__main-item" v-for="item in items" :key="item.uuid">
          <footer-column :column="item.column" :column-id="item.uuid" />
        </div>
      </template>
      <template v-else>
        <slot></slot>
      </template>
    </div>

    <!-- Footer social lang -->
    <div class="footer__social" v-if="socialLinks.length > 0">
      <div class="ft-social-list">
        <ul>
          <li v-for="item in socialLinks" :key="item.socialIcon">
            <a :href="item.socialLink" target="_blank" rel="noopener">
              <i :class="`icon-${item.socialIcon}`">
                <span class="show-for-sr">{{item.socialLabel}}</span>
              </i>
            </a>
          </li>
        </ul>
      </div>

      <div class="footer__change-lang">
        <pg-change-language-country
          :uuid="'inFooter'"
          :brandNodeName="brandNodeName"
          :site-service-param="siteServiceParam"
          :languages="languages"
          :currentLocale="currentLocale"
          :disableChangeLang="disableChangeLang"
          :currentCountry="currentCountry"
          :show-flags="showFlags"
        />
      </div>
    </div>

    <!-- Footer legal -->
    <div class="footer__legal">{{legalNote}}</div>
  </footer>
</template>
<script>
// PIAGGIO-8636: some components are useless for the implementation, but the css styles are needed for the aspect in the interface
import PgChangeLanguageCountry from "../ChangeLanguageCountry/ChangeLanguageCountry.vue";
import PgImage from "../Image/Image.vue";
import FooterColumn from "./FooterColumn.vue";
import EditorialIcon from "../EditorialIcon/EditorialIcon";
import Divider from "../Divider/Divider";
import Editorial from "../Editorial/Editorial";
import PgIconText from "../IconText/IconText";

export default {
  name: "pg-footer",
  components: {
    Editorial,
    Divider,
    PgImage,
    PgChangeLanguageCountry,
    FooterColumn,
    EditorialIcon,
    PgIconText
  },
  props: {
    isEditInterface: {
      type: Boolean,
      default: false
    },
    logo: {
      type: Object,
      default: () => ({})
    },
    brandName: {
      type: String
    },
    brandNodeName: {
      type: String
    },
    siteServiceParam: {
      type: String
    },
    brandNodePath: {
      type: String
    },
    legalNote: {
      type: String
    },
    items: {
      type: Array,
      default: () => []
    },
    socialLinks: {
      type: Array,
      default: () => []
    },
    languages: {
      type: Array,
      default: () => []
    },
    currentLocale: {
      type: String
    },
    currentCountry: {
      type: String
    },
    currentLanguage: {
      type: String
    },
    disableChangeLang: {
      type: Boolean,
      default: false
    },
    showFlags: {
      type: Boolean
    },
    includeCommunityFooter: {
      type: Boolean,
      default: false
    },
    communityFooterData: {
      type: Object,
      default: () => ({})
    },
  },
  computed: {
    communityFirstContact() {
      return {
        sectionStyle: "bggray",
        uuid: "pre-footer-contact-1",
        imageStyle: "medium",
        layout: "align-center-two-col",
        headingStyle: "medium",
        text: this.communityFooterData.contact1,
        ctaLinks: [],
        title: null
      }
    },
    communitySecondContact() {
      return {
        uuid: "pre-footer-contact-2",
        imageStyle: "medium",
        layout: "align-center-two-col",
        headingStyle: "medium",
        text: this.communityFooterData.contact2,
        ctaLinks: [],
        title: null
      }
    },
    footerClass() {
      return this.includeCommunityFooter ? "footer footer-custom-community" : "footer"
    }
  },
  mounted() {
    if (this.$mq == "small") {
      const actionMenu = document.getElementById('floating-shortcuts');
      const footer = document.getElementById('footer');
      if (actionMenu) {
        footer.style.marginBottom = "45px";
      }
    }
  }
};
</script>
