<template>
  <div v-if="visibility || $config.editMode" :class="classes" :ref="`id-${uuid}`">
    <div :class="[alignContent, verticalAlign]">
      <component
          :is="subheadingTag"
          class="editorial__subheading editorial__subheading--upper text--heading-s"
          v-if="upperSubtitle"
      >{{ upperSubtitle }}
      </component>
      <component :is="headingTag" :class="headingClass" v-if="title">{{ title }}</component>
      <component
          :is="subheadingTag"
          :class="subHeadingBoldClasses"
          v-if="subtitle"
      >{{ subtitle }}
      </component>
      <div :class="[isTextOnTwoColumns, { 'text-only' : !image }]" class="editorial__text text--body" v-html="text"></div>
      <p v-if="ctaLinks && ctaLinks.length > 0" class="editorial__cta motoplex--custom margin-top__btn-m margin-bottom__btn-m">
        <span v-for="ctaObj in ctaLinks" :key="ctaObj.uuid">
          <advanced-button-wrapper :cta-obj="ctaObj" color="primary" styleType="text" size="medium" :sectionStyle="sectionStyle" :analyticsData="analyticsData(ctaObj)"/>
        </span>
      </p>
    </div>
    <figure v-if="image" :class="`editorial__figure` + alignImage">
      <pg-image :lazy-src="image | mgnlMedia('url')" :alt="altImage"></pg-image>
    </figure>
  </div>
</template>

<script>
import AdvancedButtonWrapper from "../Advanced-Button-Wrapper/Advanced-Button-Wrapper";
import PgImage from "../Image/Image.vue";

export default {
  name: "Editorial",
  data() {
    return {
      actionParam: null
    }
  },
  components: {
    AdvancedButtonWrapper,
    PgImage
  },
  props: {
    uuid: {
      type: String
    },
    upperSubtitle: {
      type: String
    },
    title: {
      type: String
    },
    subtitle: {
      type: String
    },
    subtitleBold: {
      type: Boolean
    },
    text: {
      type: String
    },
    ctaLinks: {
      type: Array,
      required: false,
      default: () => []
    },
    image: {
      type: Object,
      required: false,
      default: null
    },
    altImage: {
      type: String,
      defaultValue: "",
      required: false
    },
    layout: {
      type: String
    },
    headingStyle: {
      type: String
    },
    imageStyle: {
      type: String
    },
    sectionStyle: {
      type: String
    },
    textOnTwoColumns: {
      type: Boolean
    },
    verticalTextAlign: {
      type: Boolean
    },
    alignContentToCenter: {
      type: Boolean,
      default: false
    },
    alignImageToCenter: {
      type: Boolean,
      default: false
    },
    actpageFullUrl: {
      type: String,
    },
    visibility: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    classes() {
      return [
        "editorial editorial--" + this.layout,
        "editorial--image-" + this.imageStyle
      ];
    },
    alignContent() {
      return Boolean(this.alignContentToCenter)
          ? "editorial__content editorial__content--align-center"
          : "editorial__content";
    },
    verticalAlign() {
      return Boolean(this.verticalTextAlign) ? " vertical-align" : ""
    },
    alignImage() {
      return Boolean(this.alignImageToCenter) ? " image-center" : "";
    },
    headingClass() {
      return this.headingStyle === "large"
          ? "editorial__title text--heading-l"
          : "editorial__title text--heading-m";
    },
    headingTag() {
      return this.headingStyle === "large" ? "h2" : "h3"; //simplified heading hierarchy for accessibility purpose
    },
    subheadingTag() {
      return this.headingStyle === "large" ? "p" : "p"; //simplified heading hierarchy for accessibility purpose
    },
    subHeadingBoldClasses() {
      return [
        'editorial__subheading',
        this.subtitleBold ? "editorial__bold" : ''
      ]
    },
    isTextOnTwoColumns() {
      return this.textOnTwoColumns ? "editorial__text--two-columns" : "";
    }
  },
  methods: {
    isInViewport(elem) {
      const rect = elem.getBoundingClientRect();
      const html = document.documentElement;
      return (
          rect.top >= 0 &&
          rect.left >= 0 &&
          rect.bottom <= (window.innerHeight || html.clientHeight) &&
          rect.right <= (window.innerWidth || html.clientWidth)
      );
    },
    scrollToAnchor(targetId) {

      if (targetId == `id-${this.uuid}`) {
        const anchor = this.$refs[targetId];

        if (!anchor) return;
        const marginToTop = 144;
        const bounding = anchor.getBoundingClientRect();
        let distanceToTop = Math.floor(bounding.top - marginToTop);

        if (window.pageYOffset === 0) {
          distanceToTop -= 62;
        }

        anchor.parentElement.tabIndex = -1;
        anchor.parentElement.focus();

        window.scrollBy({top: distanceToTop, left: 0, behavior: "smooth"});

      }
    },
    onPageScrolling() {
      const ctx = this;
      window.requestAnimationFrame(() => {
        // Run scroll functions
        const anchor = document.getElementById(`uuid-${ctx.uuid}`);
        if (ctx.isInViewport(anchor)) {
          ctx.$eventHub.emit('editorial:elementInViewport', ctx.uuid);
        }
      });
    },
    analyticsData(el) {
      return {
        evCategory: 'call_to_action',
        evAction: el.document?.url || el.link?.url || el.trackingLabel,
        evLabel: `${el.campaignId || 'No Campaign'} | ${this.actpageFullUrl}`
      }
    }
  },
  mounted() {
    // Listen for message events
    this.$eventHub.on("anchor:scrollTo", (targetId) => {
      this.scrollToAnchor(targetId);
    });
    // Listen for scroll events
    if (this.upperSubtitle) {
      window.addEventListener("scroll", this.onPageScrolling);
    }
    // Accrocchiata per mettere uno snippet automatico a tutte le landing dragone su questo componente
    if (window.location.href.toLowerCase().indexOf("landing-page") != -1 && window.location.href.toLowerCase().indexOf("dragon") != -1) {
      console.log("CUSTOM STYLE FOR LANDING DRAGONE")
      const css = ".theme-vespa .editorial__title {" +
          "    margin-bottom: 1.5rem !important;" +
          "    margin-top: 1.5rem !important;" +
          "}" +
          "" +
          ".theme-vespa .editorial__text{" +
          "    line-height: 36px !important;" +
          "    font-size: 24px !important;" +
          "}" +
          "" +
          ".theme-vespa .section.section-editorial {" +
          "    padding-top: 2rem !important;" +
          "    padding-bottom: 2rem !important;" +
          "}"
      this.styleTag = document.createElement('style');
      this.styleTag.appendChild(document.createTextNode(css));
      document.head.appendChild(this.styleTag);
    }
  },
  destroyed() {
    window.removeEventListener("scroll", this.onPageScrolling);
  }
};
</script>