<template>
  <div class="card-highlight">
    <div>
      <a :href="href" :target="targetBlank === true ? '_blank' : '_self' ">
        <div class="card-highlight__img">
          <pg-image :lazy-src="img"></pg-image>
        </div>
        <div class="card-highlight__content">
          <h3 class="text--heading-s text--primary card-highlight__text">{{text}}</h3>
          <div class="card-highlight__cta">
            <span>{{cta}}</span>
            <i class="icon-"></i>
          </div>
        </div>
      </a>
    </div>
  </div>
</template>

<script>
import PgButton from "../Button/Button.vue";
import PgImage from "../Image/Image.vue";

export default {
  name: "CardHighlight",
  components: {
    PgButton,
    PgImage,
  },
  props: {
    href: {
      type: String,
      default: ""
    },
    img: {
      type: String,
      default: ""
    },
    text: {
      type: String,
      default: ""
    },
    cta: {
      type: String,
      default: ""
    },
    targetBlank: {
      type: Boolean,
      default: false
    }
  }
};
</script>
