<template>
  <button
     v-if="isButton"
    :type="type"
    :class="classes"
    :disabled="isDisabled || isLoading"
    @click="onClick"
    :aria-expanded="ariaExpandedStatus"
    :aria-pressed="ariaPressedStatus"
    v-b-modal="action"
  >
    <i :class="iconClass" :title="text" v-if="isIcon">
      <span class="show-for-sr" :aria-hidden="ariaExpandedStatus">
        <slot></slot>
      </span>
    </i>
    <slot v-else></slot>
    <slot name="visibleContent"></slot>
  </button>
  <a v-else
    :href="href"
    :class="classes"
    :target="hrefTarget"
    :rel="setRelAttr"
     @click="sendAnalyticsData($event)"
  >
    <i :class="iconClass" :title="text" v-if="isIcon">
      <span class="show-for-sr">
        <slot></slot>
      </span>
    </i>
    <slot v-else></slot>
    <span>{{linkText}}</span>
  </a>
</template>

<script>
/**
 - Hello world this is a description
 **/

export default {
  name: "PgButton",
  props: {
    htmlType: {
      type: String,
      default: "button", //button|submit|reset
    },
    styleType: {
      type: String,
      default: "",
    },
    size: {
      type: String,
      default: "",
      //required: true
    },
    color: {
      type: String,
      default: "",
      //required: true
    },
    text: {
      type: String,
      default: "",
      //required: true
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    ariaPressed: {
      //type: String,
      default: undefined,
    },
    ariaExpanded: {
      //type: String,
      default: undefined,
    },
    href: {
      type: String,
      default: "",
    },
    linkText: {
      type: String,
      default: ""
    },
    targetBlank: {
      type: Boolean,
      default: false,
    },
    linkNofollow: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: "",
    },
    eventName: {
      type: String,
      default: "",
    },
    eventParams: {
      type: Object,
    },
    sectionStyle: {
      type: String,
      default: ""
    },
    selfManageStatus: {
      type: Boolean,
    },
    action: {
      type: String,
      default: "",
      required: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    analyticsData: {
      type: Object,
      default: () => {
      }
    }
  },

  data: function() {
    const ctx = this;
    return {
      relAttr: undefined,
      nofollow: undefined,
      //ariaExpandedStatus: ctx.ariaExpanded,
      //ariaPressedStatus: ctx.ariaPressed

      ariaExpandedStatus:
        ctx.ariaExpanded == undefined
          ? ctx.ariaExpanded
          : ctx.ariaExpanded.toString(),
      ariaPressedStatus:
        ctx.ariaPressed == undefined
          ? ctx.ariaPressed
          : ctx.ariaPressed.toString(),
    };
  },

  watch: {
    ariaExpanded: function(newVal, oldVal) {
      if (!this.selfManageStatus) {
        this.ariaExpandedStatus = newVal;
      }
    },
    ariaPressed: function(newVal, oldVal) {
      if (!this.selfManageStatus) {
        this.ariaPressedStatus = newVal;
      }
    },
  },

  computed: {
    classes() {
      return [
        "button",
        this.styleType ? "button--" + this.styleType : "",
        this.size ? "button--" + this.size : "",
        this.color ? "button--" + this.color : "",
        this.sectionStyle === "secondary" ? "secondary-bg" : "",
        this.isLoading ? "is-loading" : "",
      ];
    },
    isButton() {
      return (
        this.href === "" ||
        (this.action !== "" && this.action !== "no-selection")
      );
    },
    isIcon() {
      return this.styleType == "icon";
    },
    iconClass() {
      return this.icon;
    },
    type() {
      return this.htmlType !== "" ? this.htmlType : false;
    },
    hrefTarget() {
      return this.targetBlank ? "_blank" : undefined;
    },
    setRelAttr() {
      let attribute;
      if (this.linkNofollow) {
        attribute = "nofollow ";
      }
      if (this.targetBlank) {
        attribute += "noopener";
      }
      return attribute ? (this.relAttr = attribute) : undefined;
    },
  },

  methods: {
    async onClick() {
      //
      if (this.eventParams) {
        console.log("Event params: ", this.eventParams);
        console.log("Event name: ", this.eventName);
      }
      this.$eventHub.emit(this.eventName, this.eventParams);

      if (this.selfManageStatus) {
        switch (this.ariaExpandedStatus) {
          case "true":
            this.ariaExpandedStatus = "false";
            break;
          case "false":
            this.ariaExpandedStatus = "true";
            break;
        }

        switch (this.ariaPressedStatus) {
          case "true":
            this.ariaPressedStatus = "false";
            break;
          case "false":
            this.ariaPressedStatus = "true";
            break;
        }
      }

      if (this.analyticsData) {
        this.$analytics.pushEvent(this.analyticsData.evCategory, this.analyticsData.evAction, this.analyticsData.evLabel);
      }
    },
    sendAnalyticsData(ev) {
      if (this.analyticsData && !this.targetBlank) {
        if (window.google_tag_manager) ev.preventDefault();
        this.$analytics.pushEvent(this.analyticsData.evCategory, this.analyticsData.evAction, this.analyticsData.evLabel, this.href);
      } else if (this.analyticsData && this.targetBlank) {
        this.$analytics.pushEvent(this.analyticsData.evCategory, this.analyticsData.evAction, this.analyticsData.evLabel);
      }
    }
  },
};
</script>

<style scoped>
#root {
  background-color: lightgray;
  padding: 2rem;
}
</style>
