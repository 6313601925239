<template>
  <div>
    <div class="navigation-v2__menu-nested__menu navigation__menu-nested__menu--product" ref="navigationNested">
      <template v-if="!isNavTabsChild">
        <pg-button
            class="navigation-v2__menu-nested__back"
            :aria-expanded="false"
            event-name="expandNested"
            :event-params="{uuid: item.uuid}"
        >
          <i class="icon-arrow-sx" aria-hidden="true"></i>
          <h2 class="navigation__menu-nested__back-label">{{ item.label }}</h2>
        </pg-button>
      </template>
      <div class="listing">
        <ul :aria-label="item.label" class="listing__items" ref="listingItems">
          <li
              v-for="subitem in itemsToShow(item.nestedItems)"
              v-bind:key="subitem.uuid"
              class="listing__item"
          >
            <card-product
                :item="subitem"
                :productCardConf="productCardConf"
                :analyticsData="analyticsData(subitem)"
            ></card-product>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import PgButton from "../Button/Button.vue";
import CardProduct from "../Card-Product/Card-Product.vue";
import PgImage from "../Image/Image.vue";

export default {
  name: "NavigationProductListV2",

  components: {
    PgButton,
    PgImage,
    CardProduct
  },

  props: {
    item: {
      type: Object
    },
    productCardConf: {
      type: Object,
      default: () => {
      }
    },
    isActiveElement: {
      type: Boolean,
      default: false
    },
    highlightItems: {
      type: Array,
      required: false,
      default: () => []
    },
    posterImage: {
      type: Object,
      required: false,
      default: () => ({})
    },
    isEditInterface: {
      type: Boolean,
      default: false
    },
    isNavTabsChild: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    itemsToShow: function (items) {
      return items.slice(0, 16);
    },
    target: function (isBlank) {
      return isBlank ? "_blank" : "_self";
    },
    rel: function (noFollow, targetBlank) {
      const attribute = targetBlank ? "noopener " : "";

      return noFollow ? attribute + "nofollow" : attribute;
    },
    analyticsData(el) {
      return {
        evCategory: 'menu',
        evAction: 'menu_principale',
        evLabel: `${this.item.label || 'No Campaign'} | ${el.name}`
      }
    }
  },
  computed: {
    hasHighlightItems() {
      return this.highlightItems && this.highlightItems.length > 0;
    },
    hasPosterImage() {
      return this.posterImage && Object.keys(this.posterImage).length > 0;
    },
    isMobile() {
      return window.screen.width <= 1024;
    },
  },
};
</script>
