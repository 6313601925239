<template>
  <section :class="sectionClass" :id="`uuid-${model.uuid}`">
    <div class="section__container">
      <div :class="['grid-x align-center', { 'one_icon': model.editorialIcon.length === 1 }]">
        <div :class="classes" v-for="editorial in model.editorialIcon" :key="editorial.uuid">
          <h3 class="editorial-icon__title">
            <pg-icon-text :iconName="editorial.iconName" :title="editorial.title" />
          </h3>
          <div class="editorial-icon__text text--body" v-if="editorial.text" v-html="editorial.text"></div>
          <div class="editorial-icon__cta" v-if="editorial.linkLabel && editorial.link || editorial.document">
            <pg-button
              :href="setLinkType(editorial.document, editorial.link)"
              :color="'primary'"
              :styleType="'text'"
              :size="'medium'"
              :sectionStyle="model.sectionStyle"
              :targetBlank="editorial.onBlank"
              :linkNofollow="editorial.noFollow"
              :analyticsData="analyticsData(editorial)"
            >{{editorial.linkLabel}}</pg-button>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mgnlComponentMixin } from "@wl-vue-mixins/mixins.js";
import PgButton               from "../Button/Button.vue";
import PgIconText from "../IconText/IconText.vue";

export default {
  mixins: [mgnlComponentMixin], // !!! all the mgnl components must be configured with mgnlComponentMixin !!!

  name: "EditorialIcon",
  components: {
    PgButton,
    PgIconText
  },
  computed: {
    sectionClass() {
      return [
        "section",
        this.model.sectionStyle ? "section--" + this.model.sectionStyle : ""
      ];
    },
    classes() {
      const totalItem = this.model.editorialIcon.length;
      let style = "editorial-icon";

      if (totalItem < 3) {
        style += " editorial-icon--two-columns";
      } else if (totalItem == 3) {
        style += " editorial-icon--three-columns";
      } else {
        style += " editorial-icon--four-columns";
      }
      return style;
    }
  },
  methods: {
    setLinkType(linkToDocument, linkToPage) {
      if (linkToDocument) {
        return this.$options.filters.mgnlMedia(linkToDocument, "url");
      } else {
        return this.$options.filters.mgnlLink(linkToPage, "url");
      }
    },
    analyticsData(el) {
      return {
        evCategory: 'call_to_action',
        evAction: el.document?.url || el.link?.url || el.trackingLabel,
        evLabel: `${el.campaignId || 'No Campaign'} | ${this.common.actpageFullUrl}`
      }
    }
  }
};
</script>