<template>
  <span class="tooltip" tabindex="0" role="tooltip">
    <i class="icon-info-fill" aria-hidden="true"></i>
    <span class="tooltip__content">
      <slot></slot>
    </span>
  </span>
</template>

<script>
export default {
  name: "pg-tooltip",
  props: {
    text: {
      type: String,
      default: ""
    }
  }
};
</script>

<style>
.sb {
  padding: 50px;
}
</style>
