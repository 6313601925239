<template>
  <div>
    <div :class="nestedClasses(item.productSelect)">
      <i class="navigation__menu-nested__brand-icon icon-brand" aria-label="hidden"></i>
      <pg-button
          class="navigation__menu-nested__back"
          :aria-expanded="false"
          event-name="expandNested"
          :event-params="{uuid: item.uuid}"
      >
        <i class="icon-arrow-sx" aria-hidden="true"></i>
        {{item.label}}
      </pg-button>
      <h2 class="navigation__menu-nested__title">{{item.label}}</h2>

      <ul :aria-label="item.label">
        <li v-for="subitem in item.nestedItems" v-bind:key="subitem.uuid">
          <a
              :href="subitem.url | mgnlLink('url')"
              class="navigation__menu-nested__item"
              :target="target(subitem.onBlank)"
              :rel="rel(subitem.noFollow, subitem.onBlank)"
              :linkNofollow="subitem.noFollow"
          >{{subitem.label}}</a>
        </li>
      </ul>
    </div>
    <div class="navigation__highlights" v-if="hasHighlightItems">
      <card-highlight
          :href="hlitem.link | mgnlLink('url')"
          :text="hlitem.text"
          :cta="hlitem.label"
          :img="hlitem.image | mgnlMedia('url')"
          v-for="hlitem in item.highlightItems"
          :key="hlitem.uuid"
      ></card-highlight>
    </div>
    <div class="navigation__background" v-else-if="hasPosterImage">
      <div>
        <pg-image :lazy-src="posterImage | mgnlMedia('url')"></pg-image>
      </div>
    </div>
  </div>
</template>

<script>
import PgButton from "../Button/Button.vue";
import CardProduct from "../Card-Product/Card-Product.vue";
import PgImage from "../Image/Image.vue";
import CardHighlight  from "../Card-Highlight/Card-Highlight.vue";
import NavigationTabs from "./NavigationTabs";

export default {
  name: "NavigationItemEditorial",

  components: {
    PgButton,
    CardHighlight,
    PgImage,
    CardProduct,
    NavigationTabs
  },

  props: {
    item: {
      type: Object
    },
    productCardConf: {
      type: Object,
      default: () => {}
    },
    index: {
      type: Number
    },
    isActiveElement: {
      type: Boolean,
      default: false
    },
    highlightItems: {
      type: Array,
      required: false,
      default: () => []
    },
    posterImage: {
      type: Object,
      required: false,
      default: () => ({})
    },
    isEditInterface: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    listingClasses: function(length) {
      let listingType;
      if (length <= 6) {
        listingType = "listing--small-2-large-3";
      } else {
        listingType = "listing--small-2-large-4";
      }
      return ["listing", listingType];
    },
    itemsToShow: function(items) {
      return items.slice(0, 16);
    },
    target: function(isBlank) {
      return isBlank ? "_blank" : "_self";
    },
    rel: function(noFollow, targetBlank) {
      const attribute = targetBlank ? "noopener " : "";

      return noFollow ? attribute + "nofollow" : attribute;
    },
    nestedClasses: function(isProduct) {
      return [
        "navigation__menu-nested__menu",
        isProduct ? "navigation__menu-nested__menu--product" : ""
      ];
    },
    analyticsData(el) {
      return {
        evCategory: 'menu',
        evAction: 'menu_principale',
        evLabel: `${this.item.label || 'No Campaign'} | ${el.name}`
      }
    }
  },
  computed: {
    hasHighlightItems() {
      return this.highlightItems && this.highlightItems.length > 0;
    },
    hasPosterImage() {
      return this.posterImage && Object.keys(this.posterImage).length > 0;
    }
  }
};
</script>
