<template>
  <hr class="divider" :id="`uuid-${$attrs['jcr-uuid']}`"/>
</template>

<script>
  export default {
  name: "divider",
  inheritAttrs: false,
};
</script>

<style scoped>
#root > .divider {
  margin: 50px;
}
</style>