<template>
  <span class="icon-text">
    <i :class="`icon-text__icon icon-${iconName}`" aria-hidden="true"></i>
    <span class="icon-text__title">{{title}}</span>
  </span>
</template>

<script>
// TODO move inside TextIcon folder ???
export default {
  name: "PgIconText",
  props: {
    iconName: {
      type: String,
      defaultValue: ""
    },
    title: {
      type: String,
      defaultValue: ""
    }
  }
};
</script>