<template>
  <div :class="classes" role="alert">
    <slot></slot>
    <pg-button
      v-if="dismissible"
      class="alert__close"
      :icon="'icon-close'"
      :styleType="'icon'"
      eventName="alert:closeAlert"
      :event-params="{uuid: uuid}"
    >
      <msg id="common.alert.close.aria" />
    </pg-button>
  </div>
</template>

<script>
import PgButton from "../Button/Button.vue";

export default {
  name: "pg-alert",
  components: {
    PgButton
  },
  data() {
    return {
      visible: true
    };
  },
  props: {
    uuid: {
      type: String,
      default: ""
    },
    color: {
      type: String,
      default: ""
    },
    dismissible: {
      type: Boolean,
      default: true
    },
    sticky: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    classes() {
      return [
        "alert",
        this.color ? "alert--" + this.color : "",
        this.dismissible ? "alert--dismissible" : "",
        this.visible ? "is-visible" : "is-hidden",
        this.sticky ? "alert--sticky" : ""
      ];
    }
  },
  mounted() {
    this.$eventHub.on("alert:closeAlert", ({ uuid }) => {
      console.log("this.$eventHub.on", uuid, this.visible);
      if (uuid == this.uuid) {
        this.visible = false;
      }
    });
  },

  methods: {}
};
</script>

<style scoped>
#root > .alert:not(.alert--sticky) {
  margin: 50px;
}
</style>