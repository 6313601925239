<template>
  <div class="piaggio-select-container">
    <div style="position: relative; display: flex; align-items: center">

      <b-form-select
          :id="this.id"
          :value="this.modelValue"
          @input="(value) => handleInputEvent(value)"
          placeholder=""
          :options="this.$props.options"
          :aria-label="this.$props.label"
          :class="`piaggio-select ${this.$props.state === false ? 'piaggio-select-error' : ''}`"
          :disabled="this.disabled"
          :aria-required="this.ariaRequired"
          :state="this.state"
      ></b-form-select>

      <!-- feedback icons -->
      <i v-if="this.$props.state === false" class="icon-warning piaggio-select__error-icon"></i>
      <svg v-if="this.$props.state === true" class="piaggio-select__success-icon" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5 11.6154L10.0909 17L19 7" stroke="currentColor" stroke-width="2" stroke-linejoin="bevel"/>
      </svg>

    </div>
  </div>
</template>

<script>
import PgTooltip from "@wl-components/Tooltip/Tooltip.vue";

export default {
  name: "PiaggioFormSelect",
  components: { PgTooltip },
  model: {
    prop: 'modelValue',
    event: 'update:modelValue'
  },
  props: {
    label: String,
    modelValue: String,
    id: {
      required: false,
      type: String,
    },
    options: {
      required: true,
      type: Array // array of object { text: '', value: '' }
    },
    disabled: {
      required: false,
      type: Boolean,
      default: false
    },
    ariaRequired: {
      required: false,
      type: Boolean,
      default: false
    },
    state: {
      required: false,
      type: Boolean,
      default: true
    }
  },
  methods: {
    handleInputEvent(value) {
      this.$emit('update:modelValue', value)
    }
  }
};
</script>
