const onAfterScreenLoad = (event, ctx) => {
  /* Registration - country mapping */
  const currentCountry = ctx._self.$attrs.currentCountry;
  if (event.currentScreen === "gigya-registration-one-column"
    || event.currentScreen === "gigya-complete-registration-screen") {
    if (document.getElementsByClassName("gigya-country-field hidden")) {
      let countryTags = document.getElementsByClassName("gigya-country-field hidden");
      for (let i = 0; i < countryTags.length; i++) {
        countryTags[i].children['profile.country'].setAttribute('value', currentCountry);
      }
    }
  }
  
  /* Redirect links */
  if (event.currentScreen === "gigya-login-one-column" || "gigya-reset-password-success-screen"
    || "gigya-verification-sent-screen" || "gigya-site-social-linking-account-screen") {
    if (document.getElementById("community-faq-redirect")) {
      let faq = document.getElementById("community-faq-redirect");
      faq.setAttribute('href', ctx.faqRedirect);
    }
    if (document.getElementsByClassName("forgot-pwd-redirect")) {
      let forgotPsw = document.getElementsByClassName("forgot-pwd-redirect");
      for (let i = 0; i < forgotPsw.length; i++) {
        forgotPsw[i].setAttribute('href', ctx.forgotPswRedirect);
      }
    }
    if (document.getElementsByClassName("registration-redirect")) {
      let registration = document.getElementsByClassName("registration-redirect");
      for (let i = 0; i < registration.length; i++) {
        registration[i].setAttribute('href', ctx.registrationRedirect);
      }
    }
    if (document.getElementsByClassName("login-redirect")) {
      let login = document.getElementsByClassName("login-redirect");
      for (let i = 0; i < login.length; i++) {
        login[i].setAttribute('href', ctx.loginRedirect);
      }
    }
  }
  if (event.currentScreen === "gigya-registration-one-column" || "gigya-complete-registration-screen"
    || "gigya-update-profile-screen") {
    if (document.getElementById("community-policy-redirect")) {
      let policy = document.getElementById("community-policy-redirect");
      policy.setAttribute('href', ctx.policyRedirect);
    }
  }
  
  /* Form custom rules for birth fields */
  if (event.currentScreen === "gigya-registration-one-column" || "gigya-complete-registration-screen"
    || "gigya-update-profile-screen") {
    if (document.querySelectorAll(".gigya-birth-day > .gigya-input-text")) {
      let birthInput = document.querySelectorAll(".gigya-birth-day > .gigya-input-text");
      birthInput.forEach(input => {
        input.setAttribute("min", 1);
        input.setAttribute("max", 31);
      });
    }
    if (document.querySelectorAll(".gigya-birth-year > .gigya-input-text")) {
      let birthInput = document.querySelectorAll(".gigya-birth-year > .gigya-input-text");
      birthInput.forEach(input => {
        input.setAttribute("min", 1920);
        let today = new Date();
        let currentYear = today.getFullYear();
        input.setAttribute("max", currentYear);
      });
    }
  }
  
  /* To manage local date format */
  if (event.currentScreen === "profile-info-new" && document.getElementsByClassName('profile-birth-date')) {
    let newValue = new Date(event.profile.birthYear, event.profile.birthMonth - 1, event.profile.birthDay);
    const options = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    };
    let birthDate = document.getElementsByClassName('profile-birth-date');
    birthDate.forEach(item => {
      item.innerText = newValue.toLocaleDateString(ctx.$attrs.currentLanguage, options);
    });
  }
  
  /* Modal - close btn */
  if (event.currentScreen ==="gigya-login-one-column" || "gigya-verification-pending-screen" || "gigya-verification-sent-screen") {
    let closeBtnContainer = document.getElementsByClassName("gigya-screen-dialog-close");
    let closeBtn = closeBtnContainer[0].getElementsByTagName("a");
    let closeImg = closeBtn[0].getElementsByTagName("img");
    
    if (closeImg.length > 0) {
      closeBtn[0].removeChild(closeImg[0]);
      closeBtn[0].setAttribute("class", "gigya-close-btn-link");
    }
    
    /* Append the email at the end of the message */
    if (event.currentScreen === "gigya-verification-pending-screen") {
      let label = document.querySelector("label.register-success-email[data-screenset-roles*='instance']");
      let emailToBeVerified = "(" + event.profile.email + ")";
      let brTag = document.createElement("br");
      let emailTag = document.createElement("b");
      emailTag.innerText = emailToBeVerified;
      label.appendChild(brTag);
      label.appendChild(emailTag);
    }
  }
  
  /* Append at the end of the success message the required data */
  if (event.currentScreen === "gigya-registration-success") {
    let label = document.querySelector("label.register-success-description[data-screenset-roles*='instance']");
    let emailRegistrationSuccess = event.context.profile.email;
    let brTag = document.createElement("br");
    let emailTag = document.createElement("b");
    emailTag.innerText = emailRegistrationSuccess;
    label.appendChild(brTag);
    label.appendChild(emailTag);
  }
  if (event.currentScreen === "gigya-forgot-password-success-screen") {
    let label = document.querySelector("label.register-success-button[data-screenset-roles*='instance']");
    let userEmail = event.context.response.requestParams.loginID;
    let brTag = document.createElement("br");
    let emailTag = document.createElement("b");
    emailTag.innerText = userEmail;
    label.appendChild(brTag);
    label.appendChild(emailTag);
  }
  
  /* Show / Hide Password Button */
  let appendImage = function(element, elemClass, url, elemAlt) {
    let img = document.createElement("img");
    img.setAttribute("class", elemClass);
    img.setAttribute("alt", elemAlt);
    img.src = url;
    element.appendChild(img);
  };
  let wrap = function(el, wrapper) {
    el.parentNode.insertBefore(wrapper, el);
    wrapper.appendChild(el);
  };
  let appendButtonShowPassword = function(elemInput) {
    // Ignore if already wrapped
    let parentElement = elemInput.parentElement;
    if(parentElement.classList.contains("gigya-show-password-button-wrap")) {
      return false;
    }
    
    // icons
    let urlEyeOpen="https://test-sd18.opentext.cloud/ImConvServlet/imconv/581fe5f18e30004d9b8c32ba2b29095cf6aec608/original?use=default";
    let urlEyeOpenHover ="https://test-sd18.opentext.cloud/ImConvServlet/imconv/719006930e38e06728c953d3fbfa6b702ea1dfc5/original?use=default";
    let urlEyeClosed="https://test-sd18.opentext.cloud/ImConvServlet/imconv/c5d32e6df6d1d9fe6f5456e0639c22344457945d/original?use=default";
    let urlEyeClosedHover="https://test-sd18.opentext.cloud/ImConvServlet/imconv/87444e322390c3fafb041cfa9ee04113f16208ec/original?use=default";
    
    // Wrap input in a div
    let wrapperElem = document.createElement("div");
    wrapperElem.setAttribute("class", "gigya-show-password-button-wrap");
    wrap(elemInput, wrapperElem);
    
    parentElement = elemInput.parentElement; // == wrapperElem
    
    // Create button
    let buttonShowPassword = document.createElement("button");
    buttonShowPassword.setAttribute("class", "gigya-show-password-button");
    buttonShowPassword.setAttribute("type", "button");
    
    // Create visible div with images
    let divPasswordVisible = document.createElement("div");
    divPasswordVisible.setAttribute("class","gigya-password-visible");
    appendImage(
      divPasswordVisible,
      "gigya-show-password-img-eye gigya-show-password-img",
      urlEyeClosed,
      'hide password icon'
    );
    appendImage(
      divPasswordVisible,
      "gigya-show-password-img-eye gigya-show-password-img-hover",
      urlEyeClosedHover,
      'hide password icon red'
    );
    buttonShowPassword.appendChild(divPasswordVisible);
    
    // Create invisible div with images
    let divPasswordInvisible = document.createElement("div");
    divPasswordInvisible.setAttribute("class","gigya-password-invisible");
    appendImage(
      divPasswordInvisible,
      "gigya-show-password-img-eye gigya-show-password-img",
      urlEyeOpen,
      'show password icon'
    );
    appendImage(
      divPasswordInvisible,
      "gigya-show-password-img-eye gigya-show-password-img-hover",
      urlEyeOpenHover,
      'show password icon red'
    );
    buttonShowPassword.appendChild(divPasswordInvisible);
    
    // Add click event to button (show/hide password)
    buttonShowPassword.onclick = function() {
      buttonShowPassword.classList.toggle('gigya-show-password');
      
      if (elemInput.type === "password") {
        elemInput.type="text";
      } else {
        elemInput.type="password";
      }
    };
    
    // Append button to container
    parentElement.appendChild(buttonShowPassword);
  };
  
  // Init Show / Hide Password
  if (event.currentScreen === "gigya-registration-one-column") {
    appendButtonShowPassword(document.forms['gigya-register-form'].password);
    appendButtonShowPassword(document.querySelector('#'+event.instanceID+' .gigya-input-password'));
    appendButtonShowPassword(document.forms['gigya-register-form'].passwordRetype);
  }
  if (event.currentScreen === "gigya-registration-checkout-screen") {
    appendButtonShowPassword(document.forms['gigya-register-form'].password);
    appendButtonShowPassword(document.forms['gigya-register-form'].passwordRetype);
  }
  if (event.currentScreen === "gigya-login-one-column") {
    appendButtonShowPassword(document.forms['gigya-login-form'].password);
    appendButtonShowPassword(document.querySelector('#'+event.instanceID+' .gigya-input-password'));
  }
  if (event.currentScreen === "gigya-reset-password-screen") {
    appendButtonShowPassword(document.forms['gigya-reset-password-form'].newPassword);
    appendButtonShowPassword(document.forms['gigya-reset-password-form'].passwordRetype);
  }
  if (event.currentScreen === "gigya-change-password-screen") {
    appendButtonShowPassword(document.querySelector("#gigya-password-password"));
    appendButtonShowPassword(document.querySelector("#gigya-password-newPassword"));
    appendButtonShowPassword(document.querySelector("#gigya-password-passwordRetype"));
  }
  
  /* Registration - checkbox */
  if (event.currentScreen === "gigya-registration-one-column") {
    document.getElementById('gigya-checkbox-126872887440999460').onclick = function() {
      let checkboxes = document.getElementsByClassName('gigya-input-checkbox');
      for (let checkbox of checkboxes) {
        checkbox.checked = this.checked;
      }
    };
  }
  else if (event.currentScreen === "gigya-registration-checkout-screen") {
    document.getElementById('gigya-checkbox-29561445627791564').onclick = function() {
      let checkboxes = document.getElementsByClassName('gigya-input-checkbox');
      for (let checkbox of checkboxes) {
        checkbox.checked = this.checked;
      }
    };
  }
  else if (event.currentScreen === "gigya-complete-registration-screen") {
    document.querySelectorAll('#gigya-complete-registration-screen .registration-checkbox').forEach(function(elem) {
      if(elem.querySelector('input').checked) {
        elem.classList.add('hideSubscription');
      }
    });
    document.getElementById('gigya-checkbox-140217366575257090').onclick = function() {
      let checkboxes = document.getElementsByClassName('gigya-input-checkbox');
      for (let checkbox of checkboxes) {
        checkbox.checked = this.checked;
      }
    };
  } else if(event.currentScreen === "gigya-update-profile-screen") {
    document.getElementById('gigya-checkbox-126872887440999460').onclick = function() {
      let checkboxes = document.getElementsByClassName('gigya-input-checkbox');
      for (let checkbox of checkboxes) {
        checkbox.checked = ctx.checked;
      }
    };
  }
    /* Redirect URL EMEA */
  // Registration && ForgotPassword
  else if (event.currentScreen === "gigya-login-one-column") {
    gigya.accounts.showScreenSet({
      screenSet: 'Aprlia-2024-Community-ProfileUpdate',
      startScreen: 'gigya-update-profile-screen',
      containerID: 'gigya-update-profile-screen',
      context: event
    });
  }
  // New Customer popup
  else if (event.currentScreen === "gigya-new-customer-popup") {
    [...document.getElementsByClassName("gigya-composite-control gigya-composite-control-link pgva-btn pgva-secondary")].forEach(e => {
      e.setAttribute("href", ACC.config.encodedContextPath + "/register");
    });
  }
  // New Customer
  else if (event.currentScreen === "gigya-new-customer") {
    [...document.getElementsById("gigya-composite-control gigya-composite-control-link pgva-btn pgva-secondary")].forEach(e => {
      e.setAttribute("href", ACC.config.encodedContextPath + "/register");
    });
  }
  // Forgot Password Sent
  else if (event.currentScreen === "gigya-forgot-password-success-screen") {
    [...document.getElementsByClassName("gigya-composite-control gigya-composite-control-link pgva-btn pgva-primary")].forEach(e => {
      e.setAttribute("href", ACC.config.encodedContextPath + "/homepage");
    });
  }
  // Reset Password Success
  else if (event.currentScreen === "gigya-reset-password-success-screen") {
    [...document.getElementsByClassName("gigya-composite-control gigya-composite-control-link gigya-button-link pgva-btn pgva-primary")].forEach(e => {
      e.setAttribute("href", ACC.config.encodedContextPath + "/login");
    });
  }
  // Registration Success
  else if (event.currentScreen === "gigya-registration-success") {
    [...document.getElementsByClassName("gigya-composite-control gigya-composite-control-link pgva-btn pgva-primary")].forEach(e => {
      e.setAttribute("href", ACC.config.encodedContextPath + "/homepage");
    });
  }
}

export default onAfterScreenLoad;