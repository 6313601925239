const MsgPlugin = {
  install(Vue, options) {

    try {
      const { mode } = options; // mode can be "mock", "mgnl", "vuex"

      Vue.prototype.$msg = function (msgKey, params) {
        let messages = {};
        if (mode == "mock") {
          // mock and storybook ctx
          messages = (this.$config && this.$config.translationsMock) || {};
        } else if (mode == "vuex") {
          // configurator ctx with vuex
          messages = (this.$store.state && this.$store.state.translations) || {};
        } else if(mode == "mgnl") {
          // wl ctx
          messages = (this.$root.$data && this.$root.$data['translations']) || {};
        } else {
          console.error("MsgPlugin registered with invalid 'mode' option! Valid value are: 'mock', 'mgnl', 'vuex'")
          return;
        }

        let labelValue = (this.$config.debugMode && msgKey) || (!!messages && messages[msgKey]) || "";

        if (params && params.length > 0) {
          params.forEach((param) => {
            labelValue = labelValue.replace('|' + param.key + '|', param.value);
          });
        }

        // returning msg or msgKey itself id debug mode is enabled and the msg doesnt exist
        return labelValue;
      }

    } catch (e) {
      console.error("MsgPlugin registered without a 'mode' option! Valid value are: 'mock', 'mgnl', 'vuex'");
    }

  }
}

export default MsgPlugin;
