<template>
  <!-- This component is for the Desktop view only, contact-us mobile can be found in HeaderV2.vue -->
  <div v-if="!isMobile" :style="menuFloating">
    <ul>
      <li v-for="service in filteredSubMenuVoices" :key="service.uuid" :class="`contact-us-voice ${service.name}`">
        <pg-button :href="service.link | mgnlLink('url')" :linkText="linkTextLabel(service.name)" />
        <i class="icon-arrow-dx" aria-hidden="true"></i>
      </li>
      <li v-if="customerServiceLink" :key="customerServiceLink.uuid" :class="`contact-us-voice customer-service`">
        <pg-button :href="customerServiceLink | mgnlLink('url')" :linkText="linkTextLabel('customer-service')" />
        <i class="icon-arrow-dx" aria-hidden="true"></i>
      </li>
      <li v-if="bookAppointmentLink" :key="bookAppointmentLink.uuid" :class="`contact-us-voice book-appointment`">
        <pg-button :href="bookAppointmentLink | mgnlLink('url')" :linkText="linkTextLabel('book-appointment')" />
        <i class="icon-arrow-dx" aria-hidden="true"></i>
      </li>
    </ul>
  </div>
</template>

<script>
import PgButton from "../Button/Button.vue";
import {mgnlComponentMixin} from "../../mixins/mixins";

export default {
  components: {PgButton},
  mixins: [mgnlComponentMixin], // !!! all the mgnl components must be configured with mgnlComponentMixin !!!
  name: "pg-contacts-v2",

  data() {
    return {
      contactsUs: ["dealer-locator-dealers", "book-appointment", "dealer-locator-services"],
      menuFloating: '',
    }
  },

  props: {
    commonMock: {
      type: Object,
      default: () => {}
    },
    services: {
      type: Array,
      default: () => []
    },
    customerServiceLink: {
      type: Object,
      default: () => {}
    },
    bookAppointmentLink: {
      type: Object,
      default: () => {}
    },
  },

  computed: {
    filteredSubMenuVoices() {
      return this.services.filter(service => this.contactVoices(service.name));
    },
    isMobile() {
      return window.screen.width <= 1024;
    },
  },

  created() {
    this.calculateMenuFloating();
  },

  mounted() {
    window.addEventListener("resize", this.calculateMenuFloating);
  },

  methods: {
    calculateMenuFloating() {
      //calculate the left % when the window is > 1920px
      const currentViewport = window.innerWidth;
      const defLeft = 105;
      if(currentViewport <= 1920) {
        this.menuFloating = `--float-contacts: ${defLeft}px`
      } else {
        let calcFloat = ((currentViewport - 1920 ) / 2) + defLeft;
        this.menuFloating = `--float-contacts: ${calcFloat}px`
      }
    },
    linkTextLabel(value) {
      return this.$msg(`common.header-v2.${value}`);
    },
    contactVoices(service) {
      return service ? this.contactsUs.some(voice => service === voice) : false;
    },
  }
}


</script>