<template>
  <div class="change-language-country" v-if="!disableChangeLang">
    <div v-for="(language, index) in languages" :key="language.uuid">
      <pg-button
          v-if="index === 0"
          class="change-language-country_country"
          size="medium"
          color="primary"
          style-type="text"
          v-b-modal="`modal-multi-${uuid}`"
          eventName="PgChangeLanguageCountry:changeCountry"
          :aria-label="$msg('common.change-language-country.flag.aria')"
          :eventParams="{ uuid }"
      >
<!--        <i :class="flagIcon(currentCountry)" aria-hidden="true"></i>-->
        <msg id="common.change-language-country.country.aria"/>
      </pg-button>

      <!-- First modal -->
      <b-modal v-if="index === 0"
               :id="`modal-multi-${uuid}`"
               :title="$msg('common.change-language-country.flag.aria')"
               size="xl"
               hide-footer
               scrollable
               centered
               modal-class="modal--dialog"
      >

        <template v-slot:modal-header-close>
          <i class="close__icon icon-close" aria-hidden="true"></i>
          <span class="close__label">
            <msg :id="'common.modal.close.text'"/>
          </span>
        </template>

        <countries
            :site-service-param="siteServiceParam"
            :show-flags="showFlags"
        />

      </b-modal>


      <a
          :href="language.pageUri"
          :aria-label="$msg('common.change-language-country.lang.aria')"
          :title="$msg('common.change-language-country.lang.aria')"
          v-if="language.locale !== currentLocale "
          class="change-language-country_language"
      >{{ language.locale | subStr | uppercase}}</a>
      <span
          class="change-language-country_language"
          v-else
      >{{ language.locale | subStr | uppercase}}</span>
    </div>
  </div>
</template>


<script>
  import PgButton from "../Button/Button.vue";
  import Loader from "../Loader/Loader.vue";
  import Countries from "../Countries/Countries";

  export default {
    name: "PgChangeLanguageCountry",
    data() {
      return {
        countries: [],
        loading: true
      }
    },
    components: {
      PgButton,
      Loader,
      Countries
    },
    props: {
      uuid: {
        type: String,
        defaultValue: 'changeLang'
      },
      showFlags: {
        type: Boolean
      },
      siteServiceParam: {
        type: String
      },
      languages: {
        type: Array,
        default: () => []
      },
      currentLocale: {
        type: String
      },
      currentCountry: {
        type: String
      },
      disableChangeLang: {
        type: Boolean,
        default: false
      },
    },
    computed: {
      flagIcon: function () {
        const filters = this.$options.filters;
        return function (string) {
          if (!string) return false;
          return "flag-icon flag-icon-" + filters.subStr(string.toLowerCase());
        };
      },
    }
  };
</script>