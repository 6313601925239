<template>
  <button @click="scrollToTop" type="button" class="button button--go-to-top button--primary button--medium button--contained"
          :class="{hidden: !halfwayScroll}">
    <i class="icon icon-chevron-up"></i>
    <msg id="common.go-to-top.text"/>
  </button>
</template>

<script>

export default {
  name: "GoToTop",
  components: {},
  data() {
    return {
      halfPage: 10000000,
      halfwayScroll: false,
      scrollPosition: 0
    };
  },
  mounted() {
    window.addEventListener("scroll", this.onPageScrolling);
  },
  updated() {
  },
  computed: {},
  methods: {
    onPageScrolling() {
      this.halfPage = document.documentElement.offsetHeight / 2;
      this.scrollPosition = Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop) + window.innerHeight / 2;
      this.halfwayScroll =this.scrollPosition > this.halfPage;
    },
    scrollToTop() {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
    }
  }
};
</script>