// this mixin is the base for every vue component that represents a magnolia component
let mgnlComponentMixin = {

  // comment must be true in order to make vaadin edit mode to work as expected
  comments: true,

  // this props are collected from the JSPs
  props: {
    "jcrDataUrl": String, //TODO remove, probably not needed anymore
    "jcrPath": String, // path jcr of mgnl component node used to select the data from page data json
    "jcrUuid": String, // uuid to identify component,
    "mock": Object, // only to mock data in storybook mode
    "commonMock": Object // only to mock commonData in storybook mode
  },

  computed: {

    // retrieve all the data from a subtree, the current component subtree
    model: function () {

      // when component created inside a storybook story, a mock is returned
      if (!!this.mock) {
        return this.mock;
      }
      // when root or config is undefined, the client is not able to load facade information (due to unauthorized event from community!)
      if(this.$root.$data === undefined || this.$root.$data.pageData === undefined || this.$config === undefined || this.$config.apiPath === undefined) {
        return {}
      }

      let vehiclePDPConfPath = this.$config.vehiclePDPConfPath;
      let vehiclePDPConfName = null;
      if(vehiclePDPConfPath) {
        vehiclePDPConfName = vehiclePDPConfPath.substring(vehiclePDPConfPath.lastIndexOf('/') + 1);
      }

      let vehiclePLPConfPath = this.$config.vehiclePLPConfPath;
      let vehiclePLPConfName = null;
      if(vehiclePLPConfPath) {
        vehiclePLPConfName = vehiclePLPConfPath.substring(vehiclePLPConfPath.lastIndexOf('/') + 1);
      }

      let contentPath = (this.jcrPath || "").replace(this.$config.apiPath, "").replace(/^\//, "");

      let contentPathParts = !!contentPath ? contentPath.split("/") : [];
      let componentData = null;
      let inheritedComponentData = null;

      if (contentPathParts.length > 0) {
        componentData = this.$root.$data.pageData;
        contentPathParts.forEach((key) => {
          if (!!componentData) {
            if (key in componentData) {
              componentData = componentData[key];
            }
            else {
              componentData = componentData["component-" + key];
            }
          }
        });
      }
      // trick to populate page model
      else if (this.jcrPath == this.$config.apiPath) {
        componentData = this.$root.$data.pageData;
      }

      //TODO capire come deduplicare questo pezzo di codice

      if (!componentData && vehiclePDPConfName) {
        inheritedComponentData = this.$root.$data.pageData[vehiclePDPConfName];
        if(inheritedComponentData) {
          contentPath = (this.jcrPath || "").replace(vehiclePDPConfPath, "").replace(/^\//, "");
          contentPathParts = !!contentPath ? contentPath.split("/") : [];

          if (contentPathParts.length > 0) {
            componentData = inheritedComponentData;
            contentPathParts.forEach((key) => {
              if (!!componentData) {
                if (key in componentData) {
                  componentData = componentData[key];
                }
                else {
                  componentData = componentData["component-" + key];
                }
              }
            });
          }
        }
      }

      if (!componentData && vehiclePLPConfName) {
        inheritedComponentData = this.$root.$data.pageData[vehiclePLPConfName];
        if(inheritedComponentData) {
          contentPath = (this.jcrPath || "").replace(vehiclePLPConfPath, "").replace(/^\//, "");
          contentPathParts = !!contentPath ? contentPath.split("/") : [];

          if (contentPathParts.length > 0) {
            componentData = inheritedComponentData;
            contentPathParts.forEach((key) => {
              if (!!componentData) {
                if (key in componentData) {
                  componentData = componentData[key];
                }
                else {
                  componentData = componentData["component-" + key];
                }
              }
            });
          }
        }
      }

      return componentData || {};
    },

    // retrieve common data from mgnl page json service otherwise return mock
    common: function () {
      // when component created inside a storybook story, a mock is returned
      if (!!this.commonMock) {
        return this.commonMock;
      }

      return this.$root.commonData || {};
    }

  },

  created: function () {
    //this.retrieveDataFromBackend();
  },

}

export { mgnlComponentMixin }