<template>
  <div :class="{'vespa': isThemeVespa}" class="swatches-color tooltip" role="tooltip" tabindex="0">
    <i class="icon-swatch swatches-color__icon" :style="colorStyle">
      <span>{{colorLabel}}</span>
    </i>
    <i class="icon-swatch swatches-color__darken"></i>
    <i class="icon-swatch-active swatches-color__inactive" v-if="isThemeVespa"></i>
    <i class="icon-swatch-active swatches-color__active"></i>
    <span class="tooltip__content tooltip__content--sm">{{colorLabel}}</span>
  </div>
</template>

<script>
  export default {
    name: "pg-swatch",
    props: {
      color: {
        type: Object,
        default: () => {
        }
      },
      label: {
        type: String,
        required: false
      },
      code: {
        type: String,
        required: false
      },

    },
    computed: {
      colorStyle() {
        return `color:${this.code || this.color?.code || this.color?.colorHex}`;
      },
      colorLabel() {
        return this.label || this.color?.label || this.color?.colorName;
      },
      isThemeVespa() {
        // Check if the body contains the class "theme-vespa"
        return document.body.classList.contains('theme-vespa');
      }
    },
    methods: {}
  };
</script>
<style scoped>
  #root > * {
    margin: 5rem;
  }
</style>
