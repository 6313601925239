<template>
  <pg-button
      :href="ctaLink(ctaObj)"
      :color="color"
      :styleType="ctaObj.listStyle || styleType"
      :sectionStyle="sectionStyle"
      :size="size"
      :targetBlank="ctaObj.onBlank"
      :linkNofollow="ctaObj.noFollow"
      :key="ctaObj.uuid"
      :eventName="eventName(ctaObj)"
      :eventParams="ctaParams(ctaObj)"
      :analyticsData="analyticsData"
      :type="type"
  >
    {{ctaObj.label}}
    <slot/>
  </pg-button>
</template>

<script>
  import PgButton from "../Button/Button.vue";

  export default {

    name: "AdvancedButtonWrapper",
    components: {
      PgButton,
    },
    props: {
      color: {
        type: String,
        default: ""
      },
      ctaObj: {
        type: Object,
        default: () => {
        }
      },
      sectionStyle: {
        type: String,
        default: ""
      },
      size: {
        type: String,
        default: ""
      },
      styleType: {
        type: String,
        default: ""
      },
      analyticsData: {
        type: Object,
        default: () => {
        }
      },
      htmlType: {
        type: String,
        default: "button"
      }
    },
    computed: {
      eventName() {
        return ctaObj => {
          if (ctaObj.behaviour == 'link' || ctaObj.behaviour === undefined) return;
          return `MarketingTool:${ctaObj.behaviour}`;
        }
      },
      ctaLink() {
        return ctaObj => {
          if (ctaObj.document) {
            return this.$options.filters.mgnlMedia(ctaObj.document, "url");
          } else {
            return this.$options.filters.mgnlLink(ctaObj.link, "url");
          }
        }
      },
      ctaParams() {
        return ctaObj => {
          const params = {...ctaObj};
          // cleaning properties not needed
          delete params.link;
          delete params.document;
          delete params.listStyle;
          delete params.onBlank;
          delete params.noFollow;
          delete params.label;
          delete params.uuid;

          return params;
        }
      },
      type() {
        return this.htmlType !== "" ? this.htmlType : false;
      }
    }
  };
</script>