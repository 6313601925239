
<template>
  <img
    :src="isNotLazySrc"
    :data-src="computeLazySrc"
    :data-srcset="lazySrcset"
    :sizes="sizes"
    :alt="alt ? alt : ''"
    class="image"
    :style="style"
    :data-test="aspectRatio"
  />
</template>

<script>
import lozad from "lozad";
export default {
  name: "PgImage",
  props: {
    backgroundColor: {
      type: String,
      default: "transparent"
    },
    lazySrcset: {
      type: String,
      default: null
    },
    sizes: {
      type: String,
      default: null
    },
    src: {
      type: String,
      default: null
    },
    lazySrc: {
      type: String,
      default: null
    },
    alt: {
      type: String,
      default: ""
    },
    height: {
      type: Number,
      default: 0
    },
    width: {
      type: Number,
      default: 0
    },
    uuid: {
      type: String,
      default: null
    },
    eventName: {
      type: String,
      default: ""
    },
    productType: {
      type: String,
      default: ""
    },
    brandCode: {
      type: String,
      default: "P"
    },
    categoryCode: {
      default: ""
    }

  },
  data() {
    return {
      loading: true,
      placeholder: {
        family: "",
        vehicleE: "vehicle-vespa.png",
        vehicleP: "vehicle-piaggio.png",
        vehicleV: "vehicle-vespa.png",
        vehicleA: "vehicle-aprilia.png",
        vehicleG: "vehicle-motoguzzi.png",
        accessory: "accessory.png",
        service: "service.png",
        serviceXCareWarranty: "serviceXCareWarranty.png",
        serviceXCareMaintenance: "serviceXCareMaintenance.png",
        serviceServicePack: "serviceServicePack.png",
        serviceCheck: "serviceCheck.png"
      }
    };
  },
  computed: {

    aspectRatio() {
      // Calculate the aspect ratio of the image
      // if the width and the height are given.
      if (!parseInt(this.width) || parseInt(!this.height)) return null;

      return (parseInt(this.height) / parseInt(this.width)) * 100;
    },
    style() {
      if (this.$config.editMode) return;
      // The background color is used as a
      // placeholder while loading the image.
      // You can use the dominant color of the
      // image to improve perceived performance.
      // See: https://manu.ninja/dominant-colors-for-lazy-loading-images/
      const style = { backgroundColor: this.backgroundColor };

      if (this.width) style.width = `${this.width}px`;

      // If the image is still loading and an
      // aspect ratio could be calculated, we
      // apply the calculated aspect ratio by
      // using padding top.
      const applyAspectRatio = this.loading && this.aspectRatio;
      if (applyAspectRatio) {
        // Prevent flash of unstyled image
        // after the image is loaded.
        style.height = 0;
        // Scale the image container according
        // to the aspect ratio.
        style.paddingTop = `${this.aspectRatio}%`;
      }

      return style;
    },
    isNotLazySrc() {
      if (this.src) {
        this.loading = false;
        // if(!this.src) return this.getPlaceholder();
        return this.src;
      }
      if(!this.loading) return this.computeLazySrc;
      return false;
    },

    computeLazySrc() {
      if(this.src) return false;
      let src = this.lazySrc;
      if (!src && this.productType !== "") {
        src = this.getPlaceholder();
      }
      return src;
    }
  },
  methods: {
    getPlaceholder() {
      let src;
      switch(this.productType) {

        case "vehicle":
          const vehicleWithBrand = this.productType + this.brandCode;
          src = require(`@wl-assets/img/placeholder/${this.placeholder[vehicleWithBrand]}`)
          break;
        case "service":
          let category;
          const cat = String(this.categoryCode);
          switch(cat) {
            case "1":
              category = "XCareWarranty";
              break;
            case "4":
            case "5":
            case "6":
            case "9":
              category = "XCareMaintenance";
              break;
            case "7":
            case "8":
              category = "Check";
              break;
            default:
              category = "";

          }
          src = require(`@wl-assets/img/placeholder/${this.placeholder[this.productType + category]}`)
          break;
        default:
          if (this.placeholder[this.productType]) {
            src = require(`@wl-assets/img/placeholder/${this.placeholder[this.productType]}`)
          }
          break;
      }
    return src;
    },
    callBackFunc(el) {
      this.loading = false;
      if(this.lazySrcset) el.setAttribute('srcset', this.lazySrcset);
    }
  },
  mounted() {
    if(!this.src) {
      // As soon as the <img> element triggers
      // the `load` event, the loading state is
      // set to `false`, which removes the aspect
      // ratio we've applied earlier.
      const setLoadingState = () => {
        this.loading = false;

        /*console.log(
          "Image has loaded:",
          this.eventName,
          this.$el.src,
          this.$el.clientHeight
        );*/
        this.$eventHub.emit(this.eventName, this.$el);
      };

      this.$el.addEventListener("load", setLoadingState);

      // We remove the event listener as soon as
      // the component is destroyed to prevent
      // potential memory leaks.æ

      this.$once("hook:destroyed", () => {
        this.$el.removeEventListener("load", setLoadingState);
      });

      // We initialize Lozad.js on the root
      // element of our component.
      const observer = lozad(this.$el, {
        load: this.callBackFunc
      });

      observer.observe();
    } else this.loading = false;
  }
};
</script>

