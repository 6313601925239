<template>
  <div class="form form-select-brand" id="alert-request-quote">
    <p v-if="submitStatus === 'error'" class="alert alert--error" role="alert">
      <i aria-hidden="true" class="icon-warning-fill"></i>
      <msg id="common.form.validation.form.invalid.text" />
    </p>
    <p
      v-else-if="submitStatus === 'ok'"
      class="alert alert--confirmation"
      role="status"
    >
      <msg id="common.form.validation.form.submission.text" />
    </p>
    <form @submit.stop.prevent="onSubmit">
      <b-tabs content-class nav-class="form-steps" v-model="activeTab">
        <b-tab :title="$msg(`common.form.tab.brand.text`)">

          <!--field-group-user-data /-->
          <div class="grid-x grid-margin-x">
              <div class="w-100 d-flex justify-content-center">
                <div class="select-brand-grid">
                    <!-- cards -->
                    <div
                      v-for="(opt, index) in options"
                      :key="index"
                      class="select-brand-card"
                      :style="{ border: $v.form.brand.$model === index ? '2px solid black' : '2px solid lightgrey'}"
                      @click="() => $v.form.brand.$model= index"
                    >
                      <img :src="opt.value.brandLogo" :alt="opt.value.brandName"/>
                    </div>
                </div>
              </div>

            <!-- non hidden empty radio group to show the form field error message ( :state, :invalid-feedback props )  -->
            <b-form-group v-slot="{ ariaDescribedby }" :invalid-feedback="invalidFeedback" :state="validateState('brand')" class="invalid-feedback" style="margin: 0">
            </b-form-group>
            <!-- hidden radio group triggered from the above cards -->
            <b-form-group label="Individual radios" v-slot="{ ariaDescribedby }" :label-for="'brand'" style="visibility: hidden; height: 0; margin: 0">
              <b-form-radio :aria-required="true" v-for="(opt, index) in options" :key="index" v-model="$v.form.brand.$model" :aria-describedby="ariaDescribedby" name="brand" :value="index">{{ opt.text }}</b-form-radio>
            </b-form-group>

            <!-- submit area -->
            <div class="cell">
              <div class="form-group">
                <pg-button
                  type="submit"
                  styleType="contained"
                  color="primary"
                  size="medium"
                  class="button--block"
                >
                  <msg id="common.form.button.proceed.text" />
                </pg-button>
              </div>
            </div>

          </div>
        </b-tab>
      </b-tabs>
    </form>
  </div>
</template>

<script>
import PgButton from "../Button/Button.vue";
import PgImage from "../Image/Image.vue";
import { validationMixin } from "vuelidate";
import { mgnlComponentMixin } from "@wl-vue-mixins/mixins.js";
import { required } from "vuelidate/lib/validators";

export default {
  mixins: [validationMixin, mgnlComponentMixin],

  components: {
    PgButton,
    PgImage,
  },
  name: "FormSelectBrand",

  data() {
    return {
      activeTab: 0,
      submitStatus: null, // null, "step1", "ok"
      downloadPdfLink: null,
      form: {
        brand: null,
      },
      iframeHeight: 0,
    };
  },
  validations() {
    return { form: { brand: { required } } }
  },
  props: {
    options: {
      type: Array,
      default: []
    }
  },
  computed: {
    invalidFeedback() {
      return this.$msg(`common.form.validation.required-field.invalid.text`);
    },
  },
  watch: { },
  methods: {
    validateState(name) {
      const {$dirty, $error} = this.$v.form[name];
      return $dirty ? !$error : null; // null, true, false
    },
    onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        this.submitStatus = "error";
        return;
      } else {
        this.$emit('selected-brand', this.$props.options[this.form.brand].value)
      }
    }
  }
};
</script>
