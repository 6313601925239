const gtmManager = {
  install(Vue, options = {}) {

    Vue.prototype.$analytics = {
      pushEvent: function (evCategory, evAction, evLabel="", goToLink="", familyModel="") {
        window.dataLayer = window.dataLayer || [];

        let state = {
          'event': 'GAevent',
          'eventCategory': evCategory,
          'eventAction': evAction
        };

        if (evLabel) {
          state['eventLabel'] = evLabel;
        }

        if (goToLink) {
          state['eventCallback'] = function() {
            document.location = goToLink;
          };
          state['eventTimeout'] = 2000;
        }
  
        if (familyModel) {
          state['familyModel'] = familyModel;
        }

        dataLayer.push(state);
      }
    };
  }
};

export default gtmManager;